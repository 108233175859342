import React from 'react'
import "./intro.css"
import Me from "../../img/portrait4.webp"

export const Intro = () => {
  return (
    <div id="intro" className="intro">
        <div className="intro-left">
            <div className='intro-left-wrapper'>
                    <h2 className="intro-intro">Hello, my name is</h2>
                    <h1 className="intro-name">Aaron Cheng</h1>
                <div className="intro-title">
                    <div className="intro-title-wrapper">
                        <div className="intro-title-item">IT Professional</div>
                        <div className="intro-title-item">Web Developer</div>
                        <div className="intro-title-item">Photographer</div>
                        <div className="intro-title-item">Dreamer</div>
                    </div>
                </div>
                <p className="intro-desc">
                    I am eager in bridging the gap between users and technology through fun, accessible, and beautiful applications!
                </p>
            </div>
        </div>
        <div className="intro-right">
            <div className="intro-bg"></div>
            <img src={Me} alt="" className="intro-img" />
        </div>
    </div>
  )
}

export default Intro