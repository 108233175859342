import React from 'react'
import "./about.css"
import Me from "../../img/inthetrain.jpg"
import Bouldering from "../../img/bouldering.webp"
import Anime from "../../img/anime.webp"
import Photography from "../../img/photography.jpg"

export const About = () => {
  return (
    <div id="about" className="about">
        <div className="about-left">
            <div className="about-card bg"></div>
            <div className="about-card">
                <img src={Me} alt="" className="about-img" />
            </div>
        </div>
        <div className="about-right">
            <h1 className="about-title">About Me</h1>
            <p className="about-sub">
                Hey! I am an IT professional who dabbles in web development, design, and generally anything tech related!
            </p>
            <p className="about-desc">
                I am also a free-lance photographer shooting events, portraits, and landscapes.
                In my free time, I enjoy gaming, watching esports content, watching anime, and building keyboards.
                My expenses go towards bouldering monthly passes, Adobe subscriptions, and clothes!
            </p>

            <div className="about-hobbies-wrap">
                <div className="about-hobbies">
                    <div className="about-hobbies-card bg"></div>
                    <div className="about-hobbies-card"><img src={Bouldering} alt="" className="about-hobbies-img" /></div>
                </div>
                <div className="about-hobbies">
                    <div className="about-hobbies-card bg"></div>
                    <div className="about-hobbies-card"><img src={Anime} alt="" className="about-hobbies-img anime" /></div>
                </div>
                <div className="about-hobbies">
                    <div className="about-hobbies-card bg"></div>
                    <div className="about-hobbies-card"><img src={Photography} alt="" className="about-hobbies-img" /></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default About
